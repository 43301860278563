<template>
  <el-main>
    <el-form ref="form" :model="form" :rules="rules" label-width="130px">
      <p>围栏推送</p>
      <el-form-item label="围栏消息标题：" prop="title">
        <el-input v-model="form.title" placeholder="围栏标题" @input="getTitle"></el-input>
      </el-form-item>
      <el-form-item label="进入内容：" prop="enterContent">
        <el-input v-model="form.enterContent" @input="getContent"></el-input>
      </el-form-item>
      <el-form-item label="停留内容：" prop="stayContent">
        <el-input v-model="form.stayContent"></el-input>
      </el-form-item>
      <el-form-item label="离开内容：" prop="exitContent">
        <el-input v-model="form.exitContent"></el-input>
      </el-form-item>
      <el-form-item label="跳转路径：" prop="jump_path">
        <el-input v-model="form.jump_path" disabled>
          <el-button slot="append" @click="selectPath">选择链接</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-dialog title="选择链接" :visible.sync="pageFlag" width="52%">
      <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
    </el-dialog>
    <div class="example">
      <p>推送示例</p>
      <div class="push-show">
        <div class="push-back">
          <el-image :src="require('@/assets/image/push-header.png')"></el-image>
          <div class="push-content" v-show="showPushContent">
            <p>进入</p>
            <div class="name">
              <el-image :src="shopInfo.logo || require('@/assets/user.png')"></el-image>
              <span>{{ shopInfo.name || '店铺名称' }}</span>
              <span>30分钟前</span>
            </div>
            <div class="title">{{ form.title }}</div>
            <div class="text">{{ form.enterContent }}</div>
          </div>
          <div class="push-content" v-show="showPushContent">
            <p>停留</p>
            <div class="name">
              <el-image :src="shopInfo.logo || require('@/assets/user.png')"></el-image>
              <span>{{ shopInfo.name || '店铺名称' }}</span>
              <span>30分钟前</span>
            </div>
            <div class="title">{{ form.title }}</div>
            <div class="text">{{ form.stayContent }}</div>
          </div>
          <div class="push-content" v-show="showPushContent">
            <p>离开</p>
            <div class="name">
              <el-image :src="shopInfo.logo || require('@/assets/user.png')"></el-image>
              <span>{{ shopInfo.name || '店铺名称' }}</span>
              <span>30分钟前</span>
            </div>
            <div class="title">{{ form.title }}</div>
            <div class="text">{{ form.exitContent }}</div>
          </div>
        </div>
      </div>
    </div>
    <Preservation @preservation="submit('form')">
      <router-link to="/extension/enclosureList" slot="return" style="margin-right: 10px">
        <el-button>返回</el-button>
      </router-link>
    </Preservation>
  </el-main>
</template>

<script>
import ToPage from '@/views/addPages/toPage/index';
import Preservation from '@/components/preservation';
export default {
  components: {
    ToPage,
    Preservation,
  },
  data() {
    return {
      mapFlag: !1,
      pageFlag: !1,
      form: {
        title: '',
        trading_area: '',
        enterContent: '',
        stayContent: '',
        exitContent: '',
        jump_path: '',
      },
      id: '',
      info: {},
      disabled: !1,
      showPushContent: !1,
      rules: {
        title: [{ required: true, message: '请填写围栏消息标题', trigger: 'blur' }],
        trading_area: [{ required: true, message: '请填写地理围栏商圈', trigger: 'blur' }],
        enterContent: [{ required: true, message: '请填写进入内容', trigger: 'blur' }],
        stayContent: [{ required: true, message: '请填写停留内容', trigger: 'blur' }],
        exitContent: [{ required: true, message: '请填写离开内容', trigger: 'blur' }],
        jump_path: [{ required: true, message: '请填写跳转路径', trigger: 'blur' }],
      },
    };
  },
  computed: {
    // 用户的店铺信息
    shopInfo() {
      return this.$store.getters.shopInfo;
    },
  },
  created() {
    let form = this.form;
    form.trading_area = this.$route.query.trading_area;
    this.$axios
      .post(this.$api.push.pushInfo, {
        trading_area: form.trading_area,
      })
      .then(res => {
        if (res.code == 0) {
          form.title = res.result.title;
          form.enterContent = res.result.enterContent;
          form.stayContent = res.result.stayContent;
          form.exitContent = res.result.exitContent;
          form.jump_path = res.result.jump_path;
          this.getTitle();
          this.getContent();
        }
      });
  },
  methods: {
    selectPath() {
      this.pageFlag = !0;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
      });
    },
    getTitle(val) {
      if (this.form.title || this.form.enterContent) {
        this.showPushContent = !0;
      } else {
        this.showPushContent = !1;
      }
    },
    getContent() {
      if (this.form.title || this.form.enterContent) {
        this.showPushContent = !0;
      } else {
        this.showPushContent = !1;
      }
    },
    // 拿到跳转路径
    getPageRes(item) {
      this.form.jump_path = item.wxroute;
      this.pageFlag = !1;
    },
    submit(val) {
      this.$refs[val].validate(valid => {
        if (valid) {
          let form = this.form;
          this.$axios.post(this.$api.push.editPush, form).then(res => {
            if (res.code == 0) {
              this.$message.success('保存成功');
              this.$router.push('/extension/enclosureList');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  padding: 50px 70px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .example {
    font-weight: bold;
    font-size: 18px;
    color: #17171a;
    // img {
    //   width: 410px;
    //   height: 702px;
    // }
    .push-show {
      width: 410px;
      height: 702px;
      background: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 10px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      .push-back {
        width: 390px;
        height: 682px;
        background: url('~@/assets/image/push-back.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 10px;
        padding: 60px 20px 0 20px;
        .el-image {
          width: 100%;
          height: 200px;
        }
        .push-content {
          background: #fff;
          border-radius: 10px;
          padding: 10px 20px 20px 20px;
          margin-top: 10px;
          p {
            color: #999;
            margin-bottom: 5px;
            font-weight: 400;
            text-align: center;
            font-size: 14px;
          }
          .name {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 10px;
            .el-image {
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background: #7e7cff;
              margin-right: 10px;
            }
            span:nth-child(2) {
              margin-right: 10px;
            }
          }
          .title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;
          }
          .text {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 15px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .el-form {
    width: 750px;
    p {
      font-weight: bold;
      font-size: 18px;
      color: #17171a;
      margin-bottom: 20px;
    }
    .longitude {
      /deep/ .el-form-item__content {
        display: flex;
        .el-input {
          flex: 1;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
    .el-form-item {
      .el-input,
      .el-select {
        width: 500px;
      }
    }
  }
  .btn {
    display: flex;
    align-items: flex-end;
  }
}
</style>
